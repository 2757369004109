<template>
    <div class="page">
        <div id="top" class="search_wap">
            <div class="a-inline-block" style="padding-left:10px">
                  <label for="">月份</label>
               <a-select v-model="onMonth" style='width:100px'>
                    <a-select-option  :value="item" v-for="(item,index) in monthList" :key="index">{{item}}</a-select-option>
                </a-select>
               
            </div>
            <div class="a-inline-block">
                <a-input-search v-model="key" class="sInput" placeholder="请输入姓名搜索" @search="onSearch"/>
            </div>
        </div>
        
        
        <div v-if="this.pagination.total" style="text-align: right;margin-right: 20px;">当前数据条数{{this.pagination.total}}条</div>
        <div v-infinite-scroll="getData"
        infinite-scroll-disabled="dataLoadStop"
        infinite-scroll-distance="50">
        <div class="lists" v-for="(item,index) in data" :key="index">
         <div class="list_row">
                <div class="flex">
                    <div><label for="">姓名：</label>{{item.wname}}</div>
                    <!-- <div><label for="">编号：</label>{{item.workerid}}</div> -->
                    <div><label for="">工作点：</label>{{item.oname}}</div>
                </div>
                <div class="flex">
                    <div><label for="">底薪：</label>{{item.base}}</div>
                    <div><label for="">出勤：</label>{{item.attendance}}</div>
                </div>
                 <div class="flex">
                    <div><label for="">应得劳务费：</label>{{item.deserved_salary}}</div>                  
                    <div><label for="">实得劳务费：</label>{{item.actual_salary}}</div>
                 </div>
            </div>
            <div class="btn_con">
                <a-button type="primary" size="small" ghost @click="edit(item.id)">编辑</a-button>
            </div>
        </div>
        </div>
        <!-- <div class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
                <span>{{dataLoadNomore ? "--没有更多数据了--" : "加载中..."}}</span>
            </div> -->
            
        <a-pagination v-if="pagination.total" style="text-align: center;" @change="onChange" v-model="pagination.current" :total="pagination.total" />
        <div v-if="pagination.total==0" class="list-load-end" style="text-align:center;padding-top:25px;padding-bottom:50px">
            <span>--暂无数据--</span>
        </div>
    </div>
</template>
<script>
// import $ from "jquery";
import infiniteScroll from "vue-infinite-scroll";

import {requestXml,ajaxUrl} from '../../../assets/js/request';
export default {
    directives: {infiniteScroll},  

    data() {
        return {
            // 搜索的内容
            key: "",
            data: [],
            ajaxUrl:ajaxUrl,
            monthList:'', //可选月份列表
            onMonth:'',
               // 分页
            dataLoadStop: true,  //列表数据加载停止状态
            dataLoadNomore: false,    //列表数据加载是否完成
            pagination: {
                current: 1,
                total: 100,
                pageSize: 10, // 默认每页显示数量
            },
        };
    },
    beforeRouteEnter(to, from, next) {
      next(vm=>{
        vm.fromPath = from.path
      })
    },
    beforeDestroy(){
        sessionStorage.setItem('curpage',JSON.stringify({'key':this.key,'month':this.onMonth,'page':this.pagination}))
    },
    mounted: function(){
        if(this.fromPath == "/scrm_wap/worker/workerPayAdd") {
            var data=JSON.parse(sessionStorage.getItem('curpage'))
            sessionStorage.removeItem('curpage')
            this.pagination=data.page
            this.key=data.key
            this.onMonth=data.month
        }
        this.getMonth();
    },
    methods:{
        
        onChange(){
            window.location.href='#top'
            this.getData();
        },
        // 搜索
        onSearch(){
            this.data=[];
            this.dataLoadNomore=false;
            this.pagination.current=1;
            this.getData();
        },
        // 获取考勤月份
        getMonth(){
            requestXml("/jieb/Salary/month","POST",(res) => {
                console.log(res);
                this.monthList=res;
                this.onMonth=res[0];
                this.onSearch();
            })
        },
       getData() {
            this.dataLoadStop = true;
            requestXml("/jieb/Salary/salaryshow","POST",(res) => {
               this.data = this.data.concat(res.list);
                if(res.page.maxpage>this.pagination.current){
                this.pagination.current += 1
                this.dataLoadStop = false
                }else{
                this.dataLoadNomore = true
                }
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
            },{key:this.key,month:this.onMonth, "page": {'curpage': this.pagination.current,'pagesize':this.pagination.pageSize}})
        },
      // 编辑
        edit(id){
           this.$router.push({
                path: "/scrm_wap/worker/workerPayAdd",
                query: {
                    id: id,
                }
            });
        },
        // // 删除
        // onDelete(id){
        //     requestXml("/jieb/Worker/workerdel","POST",(res) => {
        //         console.log(res)
        //         this.getData();
        //     },{"id": id})
        // }
        
    }
};
</script>
<style scoped>
    .search_wap{
        text-align: left;
        margin-bottom: 15px;
        height: 40px;
        line-height: 40px;
        background-color: #fff;
    }
    .search_wap .ant-input-search{
        width: 40vw;
        margin: 0 5vw;
    }
    .lists{
        width: 92%;
        /* height: 26rem; */
        padding: 3rem;
        margin: 15px auto;
        background-color: #fff;
        border-radius: 10px;
        color: #202020;
    }
    /* .lists .list_row div{
        height: 5rem;
        line-height: 5rem;
        text-align: left;
    } */
    .lists .list_row .flex{
        justify-content: space-between;
        padding: 5px 0;
    }
    .lists .item{
        display: flex;
    }    
    .lists .right{
        text-align: left;
        padding-left: 20px;
        width: calc(100% - 100px );
    }
     .lists .right .flex{
         display: flex;
         justify-content: space-between;
     }
    .btn_con{
        text-align: left;
    }
    .btn_con button{
        color: #36A3FF;
        border-color: #36A3FF;
        margin: 1rem 1rem 0 0;
    }  
    .add_title{
        padding-top: 10px;padding-bottom: 5px;
    }
    
</style>
